import { createFeature, createReducer, on } from '@ngrx/store';
import { NumberActions } from './number.actions';
import { DialerNumber } from 'src/app/shared/models';

export const numberFeatureKey = 'number';

export interface NumberState {
  number: DialerNumber | null;
  numbers: DialerNumber[];
  loading: boolean;
  error: any;
}

export const initialState: NumberState = {
  number: null,
  numbers: [],
  loading: true,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    NumberActions.loadNumbers,
    (state): NumberState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    NumberActions.loadNumbersSuccess,
    (state, { numbers }): NumberState => ({
      ...state,
      numbers: [...state.numbers.filter((item) => !numbers.find(x => x.id === item.id)), ...numbers],
      loading: false,
      error: null,
    })
  ),
  on(
    NumberActions.loadNumbersFailure,
    (state, { error }): NumberState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    NumberActions.loadNumber,
    (state): NumberState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    NumberActions.loadNumberSuccess,
    (state, { number }): NumberState => ({
      ...state,
      numbers: [...state.numbers.filter((item) => number.id !== item.id), number],
      number: number,
      loading: false,
      error: null,
    }),
  ),
  on(
    NumberActions.loadNumberFailure,
    (state, { error }): NumberState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    NumberActions.addNumber,
    (state): NumberState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    NumberActions.addNumberSuccess,
    (state, { number }): NumberState => ({
      ...state,

      numbers: [...state.numbers, number],

      loading: false,

      error: null,
    }),
  ),
  on(
    NumberActions.addNumberFailure,
    (state, { error }): NumberState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    NumberActions.removeNumber,
    (state): NumberState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    NumberActions.removeNumberSuccess,
    (state, { numberId }): NumberState => ({
      ...state,
      numbers: state.numbers.filter(
        (number) => number.id !== numberId,
      ),
      loading: false,
      error: null,
    }),
  ),
  on(
    NumberActions.removeNumberFailure,
    (state, { error }): NumberState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    NumberActions.updateNumber,
    (state): NumberState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    NumberActions.updateNumberSuccess,
    (state, { number }): NumberState => ({
      ...state,
      numbers: state.numbers.map((item) =>
        // item.id === number.id ? number : item
        item.id === number.id ? { ...item, ...number } : item,
      ),
      loading: false,
      error: null,
    }),
  ),
  on(
    NumberActions.updateNumberFailure,
    (state, { error }): NumberState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
);

export const numberFeature = createFeature({
  name: numberFeatureKey,
  reducer,
});
