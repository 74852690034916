import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DialerNumber } from 'src/app/shared/models';

export const NumberActions = createActionGroup({
  source: 'Number',
  events: {
    'Load Numbers': props<{ agencyId: string }>(),
    'Load Numbers Success': props<{ numbers: DialerNumber[] }>(),
    'Load Numbers Failure': props<{ error: any }>(),
    'Load Number': props<{ numberId: string; agencyId: string }>(),
    'Load Number Success': props<{ number: DialerNumber }>(),
    'Load Number Failure': props<{ error: any }>(),
    'Add Number': props<{ number: DialerNumber; agencyId: string }>(),
    'Add Number Success': props<{ number: DialerNumber }>(),
    'Add Number Failure': props<{ error: any }>(),
    'Remove Number': props<{ numberId: string; agencyId: string }>(),
    'Remove Number Success': props<{ numberId: string }>(),
    'Remove Number Failure': props<{ error: any }>(),
    'Update Number': props<{
      numberId: string;
      agencyId: string;
      number: Partial<DialerNumber>;
    }>(),
    'Update Number Success': props<{
      numberId: string;
      agencyId: string;
      number: Partial<DialerNumber>;
    }>(),
    'Update Number Failure': props<{ error: any }>(),
  },
});
